<template>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<!--begin::Subheader-->
		<!--end::Subheader-->
		<!--begin::Entry-->
		<div class="d-flex flex-column-fluid">
			<!--begin::Container-->
			<div class="container">
				<div class="card card-custom">
					<div class="card-body p-0">
						<!--begin::Wizard-->
						<div class="wizard wizard-1" id="kt_wizard" data-wizard-state="step-first" data-wizard-clickable="false">
							<!--begin::Wizard Nav-->
							<div class="wizard-nav border-bottom">
								<div class="wizard-steps p-8 p-lg-10">
									<!--begin::Wizard Step 1 Nav-->
									<div 
										class="wizard-step"
										data-wizard-type="step"
										v-bind:class="isActiveMenu(1)"
									>
										<div class="wizard-label">
											<i class="wizard-icon flaticon-bus-stop"></i>
											<h3 class="wizard-title">1. Informations initales</h3>
										</div>
										<span class="svg-icon svg-icon-xl wizard-arrow">
											<!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
											
											<!--end::Svg Icon-->
										</span>
									</div>
									<!--end::Wizard Step 1 Nav-->
									<!--begin::Wizard Step 2 Nav-->
									<div 
										class="wizard-step"
										data-wizard-type="step"
										v-bind:class="isActiveMenu(2)"
									>
										<div class="wizard-label">
											<i class="wizard-icon flaticon-list"></i>
											<h3 class="wizard-title">2. Lien familial</h3>
										</div>
										<span class="svg-icon svg-icon-xl wizard-arrow">
											<!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
											
											<!--end::Svg Icon-->
										</span>
									</div>
									<!--end::Wizard Step 2 Nav-->
								</div>
							</div>
							<!--end::Wizard Nav-->
							<!--begin::Wizard Body-->
							<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
								<div class="col-xl-12 col-xxl-7">
									<!--begin::Wizard Form-->
									<form class="form" id="kt_form">
										<!--begin::Wizard Step 1-->
										<div 
											class="pb-5"
											data-wizard-type="step-content"
											data-wizard-state="current"
											v-show="isCurrentStep(1)"
										>
											<h3 class="mb-10 font-weight-bold text-dark">
												Informations initiales
											</h3>
											<div class="row">
												<div class="col-xl-6">
													<!--begin::Input-->
													<div class="form-group">
														<label>Prenom <span class="text-danger">*</span></label>
														<input 	
															name="firstName"
															type="text"
															class="form-control form-control-solid form-control-lg"
															placeholder="Prenom"
															:class="{ 'is-invalid': v$.form.user.firstName.$error }" 
															v-model="form.user.firstName"
														/>
														<div v-for="(error, index) of v$.form.user.firstName.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
														<span class="form-text text-muted">
															Veuillez inclure tous les prénoms légaux.
														</span>
													</div>
												</div>
												<div class="col-xl-6">
													<!--end::Input-->
													<!--begin::Input-->
													<div class="form-group">
														<label>Nom <span class="text-danger">*</span></label>
														<input 
															name="lastName"
															type="text"
															class="form-control form-control-solid form-control-lg"
															placeholder="Nom"
															:class="{ 'is-invalid': v$.form.user.lastName.$error }" 
															v-model="form.user.lastName"
														/>
														<div v-for="(error, index) of v$.form.user.lastName.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
													</div>
												</div>
											</div>

											<div class="form-group">
												<label>Adresse</label>
												<input 
													type="text"
													class="form-control form-control-solid form-control-lg"
													name="address"
													placeholder="Adresse"
													:class="{ 'is-invalid': v$.form.user.address.$error }" 
													v-model="form.user.address"
												/>
												<div v-for="(error, index) of v$.form.user.address.$errors" :key="index" class="invalid-feedback">
													<span>{{ error.$message }}</span>
												</div>
												<span class="form-text text-muted">
													Ne rien mettre si vous ne la connaisez pas
												</span>
											</div>

											<!--end::Input-->
											<div class="row">
												<div class="col-xl-6">
													<!--begin::Input-->
													<div class="form-group">
														<label>Genre <span class="text-danger">*</span></label>
														<select
															name="gender"
															class="form-control form-control-solid form-control-lg"
															:class="{ 'is-invalid': v$.form.user.gender.$error }" 
															v-model="form.user.gender"
														>
															<option value="">Select</option>
															<option value="female">Femme</option>
															<option value="male">Homme</option>
														</select>
														<div v-for="(error, index) of v$.form.user.gender.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
													</div>
													<!--end::Select-->
												</div>
												<div class="col-xl-6">
													<!--begin::Input-->
													<div class="form-group">
														<label>Date de naissance <span class="text-danger">*</span></label>
														<input
															name="birthDate"
															type="date"
															class="form-control form-control-solid form-control-lg"
															:class="{ 'is-invalid': v$.form.user.birthDate.$error }" 
															v-model="form.user.birthDate"
														/>
														<div v-for="(error, index) of v$.form.user.birthDate.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
													</div>
													<!--end::Input-->
												</div>
											</div>
											<div class="row">
												<div class="col-xl-6">
													<!--begin::Select-->
													<div class="form-group">
														<label>Nationalité <span class="text-danger">*</span></label>
														<select
															name="country"
															class="form-control form-control-solid form-control-lg"
															:class="{ 'is-invalid': v$.form.user.nationality.$error }" 
															v-model="form.user.nationality"
														>
															<option value="">Select</option>
															<option value="french">Francaise</option>
															<option value="other">Autre</option>
														</select>
														<div v-for="(error, index) of v$.form.user.nationality.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
													</div>
													<!--end::Select-->
												</div>
												<div class="col-xl-6">
													<!--begin::Select-->
													<div class="form-group">
														<label>Pays de résidence fiscal <span class="text-danger">*</span></label>
														<select
															name="country"
															class="form-control form-control-solid form-control-lg"
															:class="{ 'is-invalid': v$.form.user.fiscalCountry.$error }" 
															v-model="form.user.fiscalCountry"
														>
															<option value="">Select</option>
															<option value="french">Francaise</option>
															<option value="other">Autre</option>
														</select>
														<div v-for="(error, index) of v$.form.user.fiscalCountry.$errors" :key="index" class="invalid-feedback">
															<span>{{ error.$message }}</span>
														</div>
														<span class="form-text text-muted">
															Sera utilisé pour notre analyse fiscale.
														</span>
													</div>
													<!--end::Select-->
												</div>
											</div>
										</div>
										<!--end::Wizard Step 1-->
										<!--begin::Wizard Step 2-->
										<div
											class="pb-5"
											data-wizard-type="step-content"
											data-wizard-state="current"
											v-show="isCurrentStep(2)"
										>
											<h4 class="mb-10 font-weight-bold text-dark">
												Lien de cette personne avec votre famille
											</h4>

											<!--begin::Input-->
											<div class="form-group">
												<label>Type de lien de parenté <span class="text-danger">*</span></label>
												<select
													name="linkType"
													class="form-control form-control-solid form-control-lg"
													:class="{ 'is-invalid': v$.form.family.linkType.$error }" 
													v-model="form.family.linkType"
												>
													<option value="" disabled selected>
														Choisir
													</option>
													<option value="children">Enfant</option>
													<option value="parent">Parent</option>
													<option value="partner">Conjoint ou Partenaire</option>
												</select>
												<div v-for="(error, index) of v$.form.family.linkType.$errors" :key="index" class="invalid-feedback">
													<span>{{ error.$message }}</span>
												</div>
											</div>
											<!--end::Input-->
											<!--begin::Input-->

											<div class="form-group" v-if="form.family.linkType === 'children'">
												<label>Choix des parents <span class="text-danger">*</span></label>
												<v-select
													placeholder="Selectionnez un ou plusieurs parents"
													class="form-control form-control-solid form-control-lg"
													v-model="form.family.linkedTo"
													label="fullName"
													multiple="multiple"
													:selectable="this.areParentsSelectable"
													:reduce="(customer) => customer._id"
													:options="familyMembers">
												</v-select>
											</div>

											<div class="form-group" v-if="form.family.linkType !== 'children'">
												<label>Cette personne est {{getFamilyTypeWording(form.family.linkType)}} de: <span class="text-danger">*</span></label>
												<select 
													name="linkedTo"
													class="form-control form-control-solid form-control-lg"
													:class="{ 'is-invalid': v$.form.family.linkedTo.$error }" 
													v-model="form.family.linkedTo"
												>
													<option
													value="" disabled selected>
														Choisir
													</option>
													<option v-for="customer in familyMembers" :value="customer._id" :key="customer._id">
														{{customer.firstName}} {{customer.lastName}}
													</option>
												</select>
												<div v-for="(error, index) of v$.form.family.linkedTo.$errors" :key="index" class="invalid-feedback">
													<span>{{ error.$message }}</span>
												</div>
											</div>
										</div>
										<!--end::Wizard Step 2-->

										<!--end::Wizard Step 5-->
										<!--begin::Wizard Actions-->
										<div class="d-flex justify-content-between border-top mt-5 pt-10">
											<div class="mr-2">
												<button
													type="button"
													class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
													@click="previousStep()"
												>
													Retour
												</button>
											</div>
											<div>
												<button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-submit">Submit</button>
												<button
													type="button"
													class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
													data-wizard-type="action-next"
													@click="nextStep()"
												>
													Suivant
												</button>
											</div>
										</div>
										<!--end::Wizard Actions-->
									</form>
									<!--end::Wizard Form-->
								</div>
							</div>
							<!--end::Wizard Body-->
						</div>
						<!--end::Wizard-->
					</div>
					<!--end::Wizard-->
				</div>
			</div>
			<!--end::Container-->
		</div>
		<!--end::Entry-->
	</div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import FamilyApi from "@/api/family";

import router from "@/router";

export default {
	data() {
		return {
			finalData: {},
			form: {
				user: {},
				family: {}
			},
			currentStep: 1,
		};
	},

	setup() {
		return { v$: useVuelidate() };
	},

	created() {
		this.fetchFamilyMembers();
	},

	validations() {
		return {
			familyMembers: [],
			form: {
				user: {
					firstName: { required },
					lastName: { required },
					address: { required },
					gender: { required },
					birthDate: { required },
					nationality: { required },
					fiscalCountry: { required },
				},
				family: {
					linkType: {required},
					linkedTo: {required}
				}
			},
		}
	},

	methods: {
		getNameWithUserId(userId) {
			const index = this.familyMembers.findIndex(link => link._id === userId);

			if (index !== -1)
				return `${this.familyMembers[index].firstName} ${this.familyMembers[index].lastName}` 

			return false;
		},

		displayRegimeMatrimonial() {
			if (this.form && this.form.family.linkType === "partner")
				return true;

			return false;
		},

		getCustomWording(value) {
			if (value === true)
				return "Oui";

			return "Non";
		},

		areParentsSelectable() {
			if (!this.form.family.linkedTo)
				return true;

			if (this.form.family.linkedTo.length > 1)
				return false;

			return true;
		},

		getFamilyTypeWording(stepName) {
			switch (stepName) {
				case 'children':
					return "l'enfant"
				case 'parent':
					return "le parent"
				case 'conjoint':
					return "le conjoint ou partenaire"
			}
		},

		isActiveMenu(stepName) {
			return {'wizard-step-active': (stepName === this.currentStep) } 
		},

		isCurrentStep(stepName) {
			return stepName === this.currentStep;
		},

		isMariage() {
			if (this.form && this.form.matrimonial.type === "mariage")
				return true;

			return false;
		},

		isPACS() {
			if (this.form && this.form.matrimonial.type === "pacs")
				return true;

			return false;
		},

		previousStep() {
			this.currentStep--;
			this.form = this.finalData;

			if (this.currentStep < 1) {
				this.currentStep = 1;
				return router.push({ name: 'family.list' })
			}
		},

		fetchFamilyMembers() {
			FamilyApi.getFamilyMembers().then((data) => {
				this.familyMembers = data.familyMembers;

				for (let i = 0; i < this.familyMembers.length; i++)
					this.familyMembers[i].fullName = this.getNameWithUserId(this.familyMembers[i]._id); 
			}).catch((error) => {
				if (error.response)
					console.log(
						"[getFamilyMembers]",
						"Error from API:",
						error.response.data.message,
					);
			});
		},

		nextStep() {
			this.hasError = false;

			// Check form 1
			if (this.currentStep === 1) {
				this.v$.form.user.$touch();
				this.hasError = this.v$.form.user.$error
			}

			if (this.currentStep === 2) {
				this.v$.form.family.$touch();
				this.hasError = this.v$.form.family.$error
			}

			if (this.hasError) {
				alert("Veuillez remplir toutes les champs.");
				return;
			}

			// Add all data in final data
			this.finalData = {...this.finalData, ...this.form};

			if (this.currentStep !== 2) {
				this.currentStep++;
				return;
			}

			if (typeof this.form.family.linkedTo === "string")
				this.form.family.linkedTo = [this.form.family.linkedTo];

			// Post to the server
			FamilyApi.createFamilyMember(this.form).then(() => {
				alert("Merci ! L'équipe Kermony va traiter votre demande dans les prochaines 48h");
				router.push({ name: "family.view" });
			}).catch((error) => {
				if (error.response) {
					alert(error.response.data.message);
					console.log(
						"[createFamilyMember]",
						"Error from API:",
						error.response.data.message,
					);
				}
			});
		}
	}
}
</script>

<style type="text/css">
	.vs__dropdown-toggle {
		border: none !important;
	}

	.v-select {
		padding: 6px !important;
	}

	.vs__selected {
		font-family: Poppins,Helvetica,sans-serif !important;
	}

	.wizard.wizard-1 .wizard-nav .wizard-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
  color: #B5B5C3;
  font-size: 3.75rem;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
  color: #7E8299;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0.75rem;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
  color: #7E8299;
  font-size: 1.25rem;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
  display: none;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon {
  color: #0BB783;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon svg g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #0BB783;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon svg:hover g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step-active .wizard-label .wizard-title {
  color: #0BB783;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow {
  color: #0BB783;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow svg g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #0BB783;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow svg:hover g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
	font-size: 3.75rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
	margin-top: 0.75rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
	font-size: 1.1rem;
  }
}
@media (max-width: 767.98px) {
  .wizard.wizard-1 .wizard-nav .wizard-steps {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
	font-size: 1.5rem;
	margin-right: 1.25rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
	font-size: 1rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
	display: block;
  }
}
</style>